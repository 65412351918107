<template>
  <div class="dairy-wrapper">
    <p-header></p-header>
    <div class="banner">
      <img src="~common/images/info_top.png" width="100%" alt="">
      <div class="banner-title">精益服务只为客户成功</div>
    </div>
    <div class="dairy-container">
      <div class="dairy-content-box">
        <div class="dairy-item-box">
          <div class="dairy-item" >
            <img src="~common/images/service_1.png" alt="" class="dairy-item-img">
            <p class="dairy-item-title">1V1专属营销顾问</p>
            <p class="dairy-item-text">7*12小时答疑（9:00-21:00）</p>
            <p class="dairy-item-desc">试用期即可享受的专属客服</p>
          </div>
          <div class="dairy-item">
            <img src="~common/images/service_2.png" alt="" class="dairy-item-img">
            <p class="dairy-item-title">在线培训视频讲解</p>
            <p class="dairy-item-text">快速掌握营销密码</p>
            <p class="dairy-item-desc">活动优化技巧指导</p>
          </div>
          <div class="dairy-item" @mousemove="service3Mousemove" @mouseout="service3Mouseout">
            <img src="~common/images/service_3.png" alt="" class="dairy-item-img" v-show="!hoverService3" />
            <img src="~common/images/service_3_hover.png" alt="" class="dairy-item-img" v-show="hoverService3"/>
            <div class="dairy-item3-txt" v-show="hoverService3">扫码入群</div>
            <div class="dairy-item3-def" v-show="!hoverService3"></div>
            <p class="dairy-item-title2">GDT营销云学院</p>
            <p class="dairy-item-text">行业资讯报告速递</p>
            <p class="dairy-item-desc">优秀案例精细拆解</p>
          </div>

          

          <div class="dairy-item">
            <img src="~common/images/service_4.png" alt="" class="dairy-item-img">
            <p class="dairy-item-title">节日促销解决方案</p>
            <p class="dairy-item-text">品牌营销创意外脑</p>
            <p class="dairy-item-desc">赋能产品节日营销</p>
          </div>
          <div class="dairy-item">
            <img src="~common/images/service_5.png" alt="" class="dairy-item-img">
            <p class="dairy-item-title">品牌营销支持</p>
            <p class="dairy-item-text">优享品牌传播资源</p>
            <p class="dairy-item-desc">助力产品推广获客</p>
          </div>
        </div>
      </div>
    </div>
    <p-footer></p-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'
  import asideBar from 'components/asideBar/asideBar'

  export default {
    data() {
      return {
        hoverService3: false
      }
    },
    created() {
    },
    activated() {
      document.title = '乳品行业 _ 北京数码通科技有限公司 _ 一物一码数字营销服务商，消费者互动社群运营商'
    },
    methods: {
      service3Mousemove() {
        this.hoverService3 = true
      },
      service3Mouseout() {
        this.hoverService3 = false
      },
      goDetail(id) {
        this.$router.push({
          name: 'caseDetail', params: {
            id: id,
            type: 0
          }
        })
      }
    },
    watch: {},
    components: {
      PHeader,
      PFooter,
      asideBar
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .dairy-wrapper
    width 100%
    min-width 1270px
    .banner
      padding-top: 0.35rem;
      position relative
      text-align: center
      width: 100%
      .banner-title
        position absolute
        top 0.98rem
        left 50%
        transform: translateX(-50%)
        font-size: 0.31rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.44rem;
    .dairy-container
      width 100%
      background: #F6FBFE;
      box-sizing border-box
      .dairy-content-box
        padding .53rem 0 .5rem
        width 7.5rem
        box-sizing border-box
        color #333
        margin 0 auto
        .dairy-item-box
          display flex
          justify-content center
          flex-wrap wrap
          .dairy-item
            background: #FFFFFF;
            width 1.97rem
            cursor pointer
            padding-bottom .14rem
            margin-right .105rem
            margin-bottom .15rem
            border-radius .01rem
            border 1px solid rgba(205, 205, 205, 1)
            &:nth-child(3n)
              margin-right 0
          .dairy-item-img
            display inline-block
            width 1.97rem
            height 1.46rem
            color #333333

          .dairy-item3-def
            display inline-block
            width 1.97rem
            height 0.21rem
            background: #FFFFFF;

          .dairy-item3-txt
            display inline-block
            width 1.97rem
            height 0.21rem
            line-height: 0.21rem
            text-align: center
            background: #89BFE9;
            font-size: 0.09rem;
            color: #FFFFFF;

          .dairy-item-title
            margin-top: 0.26rem
            text-align: center;
            font-size: 0.13rem;
            font-weight: 500;
            color: #333333;
            line-height: 0.17rem;

          .dairy-item-title2
            margin-top: 0.05rem
            text-align: center;
            font-size: 0.13rem;
            font-weight: 500;
            color: #333333;
            line-height: 0.17rem;
          .dairy-item-text
            margin-top: 0.05rem
            text-align: center;
            font-size: 0.08rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.11rem;
          .dairy-item-desc
            margin-top: 0.05rem
            text-align: center;
            font-size: 0.06rem;
            font-weight: 400;
            color: #999999;
            line-height: 0.09rem;
            margin-bottom: 0.2rem
</style>
